import React from 'react';

import { Page, Blink, Box, Container, Header, Spinner, Text } from '../shared';

function Main() {
  return (
    <Page>
      <Container>
        <Box>
          <Spinner />
        </Box>
        <Header>Maintenance in progress</Header>
        <Text>
          IPlytics Platform is undergoing maintenance right now. Sorry for the
          inconvenience.
          <br />
          We should be back shortly. Thank you for your patience.
        </Text>
        <Text>
          In the meantime, check out our latest <a href="https://www.iplytics.com/category/report/">technology reports</a>.
        </Text>
        <Box>
          <Blink />
        </Box>
      </Container>
    </Page>
  );
}

export default Main;
