import React, { FunctionComponent, useContext } from 'react';
import styled, { ThemeContext, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const faded = keyframes`
  0% {
    stroke-opacity: 0.5;
  }
  50% {
    stroke-opacity: 0.125;
  }
`;

const RotingPath = styled.path<{ isReverted?: boolean }>`
  transform-origin: top left;
  animation-name: ${rotate};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: ${({ isReverted }) => isReverted ? 'reverse' : 'normal'};
  animation-duration: 5s;
  animation-fill-mode: both;
`

const FadingMarker = styled.line`
  animation-name: ${faded};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-fill-mode: both;
`

const Spinner: FunctionComponent = () => {
  const { utility: { accent, highlight }, background: { secondary } } = useContext(ThemeContext);
  return (
    <svg height={112} width={112} viewBox="-56 -56 112 112" >
      <g id="markers">
        <FadingMarker y1="-56" y2="-52" transform="rotate(0)" stroke={accent} strokeWidth="2" strokeOpacity="0.5" />
        <FadingMarker y1="-56" y2="-52" transform="rotate(72)" stroke={accent} strokeWidth="2" strokeOpacity="0.5" />
        <FadingMarker y1="-56" y2="-52" transform="rotate(144)" stroke={accent} strokeWidth="2" strokeOpacity="0.5" />
        <FadingMarker y1="-56" y2="-52" transform="rotate(216)" stroke={accent} strokeWidth="2" strokeOpacity="0.5" />
        <FadingMarker y1="-56" y2="-52" transform="rotate(288)" stroke={accent} strokeWidth="2" strokeOpacity="0.5" />
      </g>
      <g id="spinners">
        <circle className="inner full circle" cx="0" cy="0" r="38" stroke={secondary} strokeWidth="4" fill="none" />
        <RotingPath className="inner partial circle" d="M 0 38 A 38 38 0 0 0 0 -38 " stroke={highlight} strokeWidth="4" fill="none" />
        <circle className="outer full circle" cx="0" cy="0" r="48" stroke={secondary} strokeWidth="4" fill="none" />
        <RotingPath className="outer partial circle" d="M 48 0 A 48 48 0 1 0 0 48" stroke={accent} strokeWidth="4" fill="none" isReverted />
      </g>
      <g id="logo" transform="translate(-16, -16)">
        <path
          fill={accent}
          d="M22.851 18.404c.048 0 .024 0 0 0-.006 0-.012 0 0 0-.125.002-.233.002-.34 0-.588-.165-1.022-.61-1.022-1.021 0-.699.434-1.188 1.022-1.362.088.002.177.003.34 0-.05 0-.025 0 0 0 .012 0 .024 0 0 0 4.177-.076 7.362-2.832 7.49-6.127V8.19c-.132-3.507-3.508-6.33-7.83-6.468-4.01.137-7.385 2.959-7.49 6.468v18.043c.105 3.634-3.27 6.468-7.49 6.468-4.112 0-7.488-2.834-7.488-6.468V8.532c0-.83.562-1.395 1.361-1.362.588-.033 1.152.531 1.022 1.362v17.702c.13 2.208 2.381 4.095 5.106 4.085 2.83.01 5.08-1.88 5.106-4.085V8.532c-.026-4.748 4.475-8.51 9.873-8.51 5.71 0 10.212 3.763 10.212 8.51v1.362c0 4.701-4.286 8.398-9.872 8.51zM1.234.021c.658 0 1.192.534 1.192 1.192v1.702a1.191 1.191 0 1 1-2.383 0V1.213C.043.555.576.02 1.234.02z"
        />
      </g>
    </svg>
  );
};

export default Spinner;


{/* <Mark index={0} total={5} />
      <Mark index={1} total={5} />
      <Mark index={2} total={5} />
      <Mark index={3} total={5} />
      <Mark index={4} total={5} />
      <Wheel color={theme.background.secondary} size={100} />
      <ThreeQuarterWheel color={theme.utility.accent} size={100} />
      <Wheel color={theme.background.secondary} size={80} />
      <HalfWheel color={theme.utility.highlight} size={80} /> */}